import domReady from '@roots/sage/client/dom-ready'
import bootstrapAlpine from './bootstrap/alpine/index.js'
import Router from './utils/router.js'
import routes from './routes/index.js'

/** Populate Router instance with DOM routes */
const Routes = new Router(routes)

/**
 * Application entrypoint
 */
domReady(async () => {
  // ...
  Routes.loadEvents()
  bootstrapAlpine()
})

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(console.error)
}
