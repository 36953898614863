import Alpine from 'alpinejs'
import { disableBodyScroll, enableBodyScroll } from './body-lock.js'

const overlay = () =>
  Alpine.store('modal', {
    on: false,
    open() {
      console.log('open')
      this.on = true
      disableBodyScroll()
    },
    close() {
      console.log('close')
      this.on = false
      enableBodyScroll()
    },
  })

const contactForm = () => {
  Alpine.store('contactForm', {
    on: false,
    open() {
      console.log('open')
      this.on = true
      disableBodyScroll()
    },
    close() {
      console.log('close')
      this.on = false
      enableBodyScroll()
    },
  })

  // url에 ui-action 파라미터에 contact-form-{status}가 있다면 해당 status에 맞는 contact form을 보여준다.
  const url = new URL(window.location.href)
  const action = url.searchParams.get('ui-action')
  console.log(action)
  if (action === 'contact-form-open') {
    Alpine.store('contactForm').open()
  }
}

const modal = () => {
  overlay()
  contactForm()
}

export default modal
