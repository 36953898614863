import {
  disablePageScroll as _disableBodyScroll,
  enablePageScroll as _enableBodyScroll,
} from 'scroll-lock'
export function disableBodyScroll() {
  _disableBodyScroll(document.querySelector('body'))
  document.querySelector('#wpadminbar')?.classList.add('pr-[15px]')
  document.querySelector('#m-gnb')?.classList.add('pr-[15px]')
}

window.disableBodyScroll = disableBodyScroll

export function enableBodyScroll() {
  _enableBodyScroll(document.querySelector('body'))
  document.querySelector('#wpadminbar')?.classList.remove('pr-[15px]')
  document.querySelector('#m-gnb')?.classList.remove('pr-[15px]')
}

window.enableBodyScroll = enableBodyScroll
